<template>
  <div complete-ok>
    <ProgressTitle :is-final="true" />
    <FontIcon name="check-over-circle" />
    <h2>{{ $t('thankYou') }}</h2>
    <div>
      <p>{{ $t('_.welcomeOnboard') }}</p>
      <p>{{ $t('_.descriptionOk1') }}</p>
      <p v-html="$t('_.descriptionOk2', { webSiteUrl, webSiteName })"></p>
    </div>
    <ProgressButton :button-name="buttonName" @click="complete"/>
  </div>
</template>

<script>
import { webSiteInfo } from '@/utils/webSiteUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { redirectCompleteUrl } from '@/utils/baseUtil';
import { autoLogin, close, isClient } from '@/utils/clientUtil';
import { getCookie } from '@shared/utils/cookieUtils.mjs';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { restrictType } from '@/constants/base/onboarding/kyc';
import { siteIds } from '@/constants/base/siteMap.mjs';

export default {
  name: 'CompleteOk',
  lexicon: 'onBoarding.complete',
  components: { FontIcon, ProgressTitle, ProgressButton },
  data() {
    return {
      webSiteUrl: null,
      webSiteName: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    language() {
      return this.$services.cookie.getLanguage();
    },
    buttonName() {
      return this.site === siteIds.GGPNL ? 'startPlaying' : 'complete';
    }
  },
  methods: {
    initialize() {
      const { name, url } = webSiteInfo(this.site);
      this.webSiteName = name;
      this.webSiteUrl = url;
    },
    async complete() {
      if (!await redirectCompleteUrl(this)) {
        if (isClient()) {
          const kycStatusInfo = await this.$services.kyc.getPlayerKycStatus();
          const existRestrictLogin = kycStatusInfo?.KycRequisition?.Restrics?.includes(restrictType.Login);
          if (existRestrictLogin) close(this);
          else autoLogin(this);
        } else close(this);
      }
    }
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[complete-ok] { .tc(); .pt(24);
  [font-icon] { .p(16); .fs(50); .c(#1db77d); }
  h2 { .fs(32); .mt(12); .regular(); }
  > div { .fs(18,20); .mt(32); .c(@c-text-desc); .regular(); .mb(55); .tl();
    >p { .mv(1.5em); }
    >p+p { .mt(0.5em); }
    a { .c(@c-link); .medium();  }
  }
  [progress-button] { .fs(20); }
}
</style>
